<template>
  <d-side-bar
    :model="display"
    :left="position === 'left' ? 0 : 'auto'"
    :right="position === 'right' ? 0 : 'auto'"
    :right-position="position === 'right'"
    :width="position === 'right' ? '880px' : '840px'"
    :backdrop="true"
    identifier="multiple-booking-identifier"
    bg-dark="unset"
    @hidden="onHide"
    @shown="$emit('shown')"
    @change="$emit('change')"
  >
    <div slot="header" class="header-multiple">
      <div class="d-flex">
        <div class="booking-playground-name">
          {{ slotProps.playground.name }}
        </div>
        <div class="booking-date-time ml-2">
          - {{ $toTimeFormat(startAt) }} - {{ $toTimeFormat(endAt) }}
        </div>
      </div>
    </div>
    <div slot="body" class="body-multiple">
      <b-row class="mt-2">
        <b-col cols="3">
          <div class="shadow-block d-flex justify-content-center">
            <div
              :class="slotCardColor"
              class="mr-2 default-text text-ellipsis"
            >
              {{ count.all }} / {{ slotProps.maxParticipantsCountLimit }}
              {{ $t('views.bookings.index.multiple.participants') }}
            </div>
          </div>
        </b-col>
        <b-col cols="2">
          <div class="shadow-block d-flex justify-content-center">
            <div class="green-text text-ellipsis">
              {{ count.confirmedParticipantsCount }}
              {{ presentLabel }}
            </div>
          </div>
        </b-col>
        <b-col cols="2">
          <div class="shadow-block d-flex justify-content-center">
            <div class="red-text text-ellipsis">
              {{ count.unconfirmedParticipantsCount }}
              {{ absentLabel }}
            </div>
          </div>
        </b-col>
        <b-col>
          <div class="has-search mr-2 ">
            <span class="fa fa-search form-control-feedback"/>
            <input
              v-model="filter"
              :placeholder="$store.getters['layout/getInnerWidth'] < 600 ? '' : $t('components.doinsport.table.search')"
              type="text"
              class="form-control"
              @keyup="searchFilter"
            >
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-5">
        <b-col
          id="table-content-id"
          cols="12"
        >
          <div class="border-bottom-grey"/>
          <d-table
            identifier="booking-el"
            :per-page="500"
            :custom-view="true"
            :show-pagination="false"
            :tr-cursor-pointer="false"
            :items="items"
            :is-busy="isBusy"
            :tablefields="fields"
            :totalRows="totalRows"
            :sticky-header="stickyHeader"
            @on:booking-el:delete="deleteBooking"
            @on:data-table-btn:edit="onBookingUpdate"
          />
          <div class="border-bottom-grey"/>
        </b-col>
      </b-row>
    </div>
    <div slot="footer">
      <div class="btn-content">
        <d-button
          text="views.bookings.index.multiple.new-reservation"
          class="d-btn-sm font-text-title d-btn btn d-btn-primary-new btns-display"
          @on:button-click="$emit('on:new-booking:click')"
        />
      </div>
    </div>
  </d-side-bar>
</template>
<script>
import {hydrate} from "@/utils/form";
import {UTC_SERVER_DATE_FORMAT} from "@/utils/date";
import {MULTIPLE_BOOKING_FIELDS} from "@/constants/multiple-booking/resume";
import MultipleBookingResume from "@/classes/doinsport/table/MultipleBookingResume";
import {getBlockPricesByPlaygroundId} from "@api/doinsport/services/timetable/blocks/price/price.api";
import {deleteBooking, getBooking, getMultipleBookingsBySlot} from "@api/doinsport/services/bookings/booking.api";
import {enableBodyScrollY} from "@/utils/style";

export default {
  data: () => ({
    bookings: [],
    confirmed: 0,
    unconfirmed: 0,
    blockPrices: [],
    itemsPerPage: 10,
    currentPage: 1,
    totalRows: 1,
    isBusy: false,
    timer: null,
    filter: '',
  }),
  props: {
    position: {
      type: String,
      default: 'left'
    },
    display: {
      type: Boolean,
      default: false
    },
    slotProps: {
      type: Object,
      default: null
    },
  },
  watch: {
    display: function (val) {
      if (val) {
        this.loadMultipleBookings();
      }
    }
  },
  computed: {
    stickyHeader() {
      return window.innerHeight - 190 + 'px';
    },
    count() {
      if (this.filter.trim() === '') {
        this.confirmed = 0;
        this.unconfirmed = 0;

        for (const booking of this.bookings) {
          this.confirmed += booking.bookingTimetableBlockPrice.confirmedParticipantsCount;
          this.unconfirmed += booking.bookingTimetableBlockPrice.unconfirmedParticipantsCount;
        }
      }

      return {
        confirmedParticipantsCount: this.confirmed,
        unconfirmedParticipantsCount: this.unconfirmed,
        all: this.unconfirmed + this.confirmed
      }
    },
    startAt() {
      return this.$moment(this.slotProps.slotObject.date);
    },
    endAt() {
      return this.startAt.clone().add(this.slotProps.slotObject.step, 'minutes');
    },
    fields() {
      return MULTIPLE_BOOKING_FIELDS;
    },
    items() {
      return this.bookings;
    },
    slotCardColor() {
      switch (this.slotProps.slotAvailability) {
        case 'empty-slot':
        case 'full-slot':
          return this.slotProps.slotAvailability;
        case 'medium-slot':
          return 'half-empty-slot';
        default:
          return '';

      }
    },
    innerWidth() {
      return this.$store.getters['layout/getInnerWidth'] > 700;
    },
    absentLabel() {
      if (this.count.unconfirmedParticipantsCount === 1 || this.count.unconfirmedParticipantsCount === 0) {
        return this.$t('views.bookings.index.multiple.one-absent');
      } else {
        return this.$t('views.bookings.index.multiple.many-absent');
      }
    },
    presentLabel() {
      if (this.count.confirmedParticipantsCount === 1 || this.count.confirmedParticipantsCount === 0) {
        return this.$t('views.bookings.index.multiple.one-present');
      } else {
        return this.$t('views.bookings.index.multiple.many-present');
      }
    }
  },
  methods: {
    onHide() {
      const storedBooking = this.$store.getters["multipleBooking/getTmpBooking"];

      if (null !== storedBooking) {
        getBooking(storedBooking.id)
          .then((response) => {
            this.refreshResume(response.data);
          })
        ;
      }
      this.$emit('hidden');
    },
    deleteBooking(booking) {
      deleteBooking(booking.id, {canceled: true})
        .then(() => {
          const bookingToDelete = this.bookings.find(el => el.id === booking.id);

          this.bookings.splice(this.bookings.indexOf(bookingToDelete), 1);
          document.getElementById('identifier-' + booking.id).click();
        })
        .finally(() => {
          this.updatePlanningMultipleSlot(booking.bookingParticipants.values);
        })
      ;
    },
    onBookingUpdate(booking) {
      enableBodyScrollY();
      this.$router.push({name: 'booking_update', params: {id: booking.id}});
    },
    updatePlanningMultipleSlot(booking) {
      this.$bus.$emit('on:multiple-bookings:update', {
        booking: booking,
        slotProps: this.slotProps,
        count: this.count
      });
    },
    searchFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.loadMultipleBookings();
      }, 400);
    },
    toUtcFormat(date) {
      return this.$toUtcCustomFormat({date: date, format: UTC_SERVER_DATE_FORMAT});
    },
    refreshResume(booking) {
      const bookingEl = new MultipleBookingResume(booking, {slotProps: this.slotProps, serialize: true})

      const findBookingEl = this.bookings.find(el => el.id === bookingEl.id);
      if ("undefined" !== typeof findBookingEl) {
        hydrate(findBookingEl, bookingEl);

        this.updatePlanningMultipleSlot(findBookingEl.bookingParticipants.values);
      }
    },
    async loadMultipleBookings() {
      if (this.display === true) {
        this.isBusy = true;
        this.bookings = [];

        getMultipleBookingsBySlot(this.slotProps.playground.id, this.toUtcFormat(this.startAt), this.toUtcFormat(this.endAt), this.filter)
          .then((response) => {
            this.totalRows = response.data['hydra:totalItems'];

            for (const el of response.data['hydra:member']) {
              this.bookings.push(new MultipleBookingResume(el, {slotProps: this.slotProps, serialize: true}));
            }
          })
          .finally(() => {
            this.isBusy = false;
            this.loadBlockPrices();
          })
        ;
      }
    },
    loadBlockPrices() {
      getBlockPricesByPlaygroundId(this.slotProps.playground.id)
        .then((response) => {
          this.$store.dispatch('playgrounds/saveBlockPricesSelection', response.data['hydra:member']);
        })
      ;
    },
  },
  mounted() {
    this.$bus.$on('on:multiple-booking-model:update', this.refreshResume);
  },
  beforeDestroy() {
    this.$store.commit("multipleBooking/setTmpBooking", null);

    try {
      this.$bus.$off('on:multiple-booking-model:update');
    } catch (e) {
    }
  },
}
</script>

<style scoped lang="scss">
@import "@lazy/bookings/_multiple-booking-resume.scss";

/deep/ .b-sidebar > .b-sidebar-body {
  overflow-x: hidden;
  overflow-y: hidden;
}

</style>
