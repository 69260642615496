import {hydrate} from "@/utils/form";

export default class MultipleBookingResume {
  id = null;
  bookingParticipants = null;
  bookingTimetableBlockPrice = null;
  participantPresence = null;
  bookingPayment = null;
  actions = [];

  constructor(object, options) {
    if ("undefined" !== typeof options) {
      if (options.serialize) {
        this.serialize(object, options.slotProps);
      }
    }
  }

  serialize(object, slotProps) {
    hydrate(this, object);
    this.initCells(object, slotProps);
  }

  initCells(object, slotProps) {
    this.bookingParticipants = {values: object, slotProps: slotProps};
    this.bookingTimetableBlockPrice = object;
    this.participantPresence = object;
    this.bookingPayment = object;
    this.actions = ['edit', 'delete'];
  }
}
