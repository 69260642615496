export const MULTIPLE_BOOKING_FIELDS =
  [
    {
      key: 'bookingParticipants',
      class: 'custom-participant-about'
    },
    'bookingTimetableBlockPrice',
    'participantPresence',
    'bookingPayment',
    'actions'
  ]
;
