<template>
  <b-row>
    <b-col class="d-block align-items-center justify-content-center d-flex">
      <span
        class="start-end-slot"
        :class="labelColor"
      >
        {{ slotLabel }}
      </span>
    </b-col>
    <b-col
      v-if="nbParticipants > 0"
      class="d-block justify-content-center d-flex"
    >
      <span class="participants-vertical-label">
        {{ nbParticipants + ' / ' + maxParticipantsCountLimit }}
      </span>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: 'Small',
  props: [
    'slotLabel',
    'labelColor',
    'nbParticipants',
    'maxParticipantsCountLimit',
  ],
}
</script>
<style scoped lang="scss">
@import "@lazy/planning/multiple-slot/_label.scss";
</style>
