<template>
  <div
    :style="setStyleSlot(slotObject.step, slotObject.date)"
    :class="'hover-step slot ' + slotBackgroundColor"
  >
    <div
      :style="(orientation === 'vertical')?'height:' + (slotObject.step * size) + 'px':'width:' + (slotObject.step * size * 2) + 'px'"
      class="text-slot"
      @click.stop="$emit('on:slot-click')"
    >
      {{ slotLabel }}
    </div>
  </div>
</template>
<script>

export default {
  props: {
    orientation: {
      type: String,
      default: () => 'vertical'
    },
    slotObject: {
      type: Object,
      default: () => {
      },
    },
    playground: {
      type: Object,
      default: () => {
      },
    },
    stepSlot: {
      type: Number,
      default: 0,
    },
    size: {
      type: Number,
      default: 1,
    }
  },
  computed: {
    slotBackgroundColor() {
      const today = this.$moment.utc().tz(this.$store.getters["auth/currentClub"].timezone).format('YYYY-MM-DD');
      const planningDate = this.$store.getters["planning/getDate"];
      const timeNow = this.$moment(new Date()).tz(this.$store.getters["auth/currentClub"].timezone).format('HH:mm');
      const slotDate = this.$moment(this.slotObject.date);
      let endSlotTime = slotDate.tz(this.$store.getters["auth/currentClub"].timezone).add(this.slotObject.step, 'minutes').format('HH:mm');
      endSlotTime = endSlotTime === '00:00' ? '23:59' : endSlotTime;

      if (planningDate > today) {
        return 'next-date-slot-background';
      } else if (planningDate < today) {
        return 'previous-date-slot-background';
      } else if (planningDate === today && endSlotTime < timeNow) {
        return 'passed-date-slot-background'
      } else {
        return '';
      }
    },
    slotLabel() {
      let label = this.$moment(this.slotObject.date);
      return label.tz(this.$store.getters["auth/currentClub"].timezone).format('HH:mm');
    }
  }
  ,
  methods: {
    setStyleSlot(height, date) {
      const timezone = this.$store.getters["auth/currentClub"].timezone;
      const hhFormat = this.$moment.utc(date).tz(timezone).format('HH');
      const mmFormat = this.$moment.utc(date).tz(timezone).format('mm');
      const storedDate = this.$store.getters["planning/getDate"];
      const slotDate = this.$moment.utc(storedDate).tz(timezone);

      slotDate.set({hour: hhFormat, minute: mmFormat, second: 0, millisecond: 0});
      slotDate.toISOString();
      slotDate.format();

      if (this.orientation === 'vertical') {
        return {
          'height': (height * this.size) + 'px',
          'order': '' + slotDate.unix(),
        }
      } else {
        return {
          'min-width': (2 * height * this.size) + 'px',
          'max-width': (2 * height * this.size) + 'px',
          'border-right': '1pt solid rgba(100, 100, 100, 0.1)',
          'order': '' + slotDate.unix(),
        }
      }
    }
  }
  ,
}
</script>
<style scoped lang="scss">
.hover-step {
  border-bottom: 1pt solid rgba(100, 100, 100, 0.1);
  background-color: white;
}

.hover-step:hover {
  background-color: #508FF458;
  opacity: 0.8;
  border-radius: 6px;
}

.text-slot {
  padding: 1pt;
  color: transparent;
  font-size: 0.6rem;
  width: 100%;
  height: 100%;
}

.text-slot:hover {
  color: #424242;
}

.disabled-slot {
  background: repeating-linear-gradient(45deg, #eff4f5, #e8e9ec 7px, #f1f0f0 7px, #efefef 14px);
  border-bottom: 1pt solid rgba(100, 100, 100, 0.1);
}

.previous-date-slot-background {
  background-color: rgba(232, 0, 101, 0.02);
}

.next-date-slot-background {
  background-color: #e5ffe840;
}

.passed-date-slot-background {
  background-color: #F5F5F5;
}

</style>
