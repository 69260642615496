<template>
  <div
    :style="setStyleSlot(slotObject.step, slotObject.date)"
    :class="slotBackgroundColor + hasPointer + hasHover"
    class="background-grey"
  >
    <div
      :style="(orientation === 'vertical')?'height:' + slotHeight + 'px':'width:' + slotWidth + 'px'"
      :class="slotCardColor"
      class="d-flex align-items-center justify-content-center"
      @click="displayResume"
    >
      <Component
        :is="getComponentSlot"
        :slot-label="slotLabel"
        :slot-width="slotWidth"
        :label-color="labelColor"
        :orientation="orientation"
        :nb-participants="nbParticipants"
        :max-participants-count-limit="maxParticipantsCountLimit"
      />
    </div>
    <resume
      v-if="null !== resumeModel"
      :key="'resume' + resumeKey"
      :display="display"
      :slot-props="slotProps"
      @on:new-booking:click="newMultipleBooking"
      @hidden="onHide"
    />
  </div>
</template>
<script>
import Small from "@custom/planning/multiple-slot/slot/Small";
import Medium from "@custom/planning/multiple-slot/slot/Medium";
import Resume from "@custom/booking/multiple-booking/Resume";
import {toTimeFormat} from "@/utils/timezone";
import {disableBodyScrollY, enableBodyScrollY} from "@/utils/style";

export default {
  data: () => ({
    resumeKey: 0,
    isHovered: '',
    display: false,
    resumeModel: null,
    participantsCount: 0,
    bookingsCount: 0,
  }),
  components: {
    Resume,
    Small,
    Medium,
  },
  props: {
    leisureSlots: {
      type: Object,
      default: () => {
      },
    },
    orientation: {
      type: String,
      default: () => 'vertical'
    },
    slotObject: {
      type: Object,
      default: () => {
      },
    },
    playground: {
      type: Object,
      default: () => {
      },
    },
    stepSlot: {
      type: Number,
      default: 0,
    },
    size: {
      type: Number,
      default: 1,
    }
  },
  watch: {
    leisureSlots: {
      deep: true,
      handler() {
        this.loadNbParticipantBySlot();
      }
    }
  },
  computed: {
    slotProps() {
      return {
        slotObject: this.slotObject,
        slotAvailability: this.slotAvailability,
        playground: this.playground,
        nbParticipants: this.nbParticipants,
        maxParticipantsCountLimit: this.maxParticipantsCountLimit
      }
    },
    hasPointer() {
      return this.display ? '' : ' pointer';
    },
    hasHover () {
      if (this.resumeModel === null) {
        if (this.nbParticipants === 0 && this.nbBookings === 0)  {
          return ' hover-slot';
        } else {
          return this.slotAvailability === '' ? '' : ' ' + this.slotAvailability + '-hover';
        }

      } else {
        return '';
      }
    },
    slotCardColor() {
      if (this.isEmptySlot) {
        return 'empty-slot-color';
      } else if (this.isHalfEmptySlot) {
        return 'medium-slot-color';
      } else if (this.isFullSlot) {
        return 'full-slot-color';
      } else {
        return '';
      }
    },
    labelColor() {
      return this.slotCardColor === '' ? 'default-slot-color' : this.slotCardColor;
    },
    nbParticipants() {
      return this.participantsCount;
    },
    nbBookings() {
      return this.bookingsCount;
    },
    maxParticipantsCountLimit() {
      return this.slotObject.block.maxParticipantsCountLimit ? this.slotObject.block.maxParticipantsCountLimit : 50;
    },
    isEmptySlot() {
      const emptyCountLimit = parseInt(this.maxParticipantsCountLimit) / 2;
      if (this.nbParticipants === 0 && this.nbBookings > 0) {
        return true;
      }
      return (this.nbParticipants > 0 && this.nbParticipants < emptyCountLimit);
    },
    slotAvailability() {
      if (this.isEmptySlot) {
        return 'empty-slot';
      } else if (this.isHalfEmptySlot) {
        return 'medium-slot';
      } else if (this.isFullSlot) {
        return 'full-slot';
      } else {
        return '';
      }
    },
    isHalfEmptySlot() {
      const emptyCountLimit = parseInt(this.maxParticipantsCountLimit) / 2;
      const mediumCountLimit = parseInt(this.maxParticipantsCountLimit) / 4;

      return this.nbParticipants >= emptyCountLimit && this.nbParticipants < emptyCountLimit + mediumCountLimit;
    },
    isFullSlot() {
      const emptyCountLimit = parseInt(this.maxParticipantsCountLimit) / 2;
      const mediumCountLimit = parseInt(this.maxParticipantsCountLimit) / 4;

      return this.nbParticipants >= emptyCountLimit + mediumCountLimit;
    },
    slotHeight() {
      return this.slotObject.step * this.size;
    },
    slotWidth() {
      return this.slotObject.step * this.size * 2;
    },
    slotBackgroundColor() {
      const today = this.$moment.utc().tz(this.$store.getters["auth/currentClub"].timezone).format('YYYY-MM-DD');
      const planningDate = this.$store.getters["planning/getDate"];
      const timeNow = this.$moment(new Date()).tz(this.$store.getters["auth/currentClub"].timezone).format('HH:mm');
      const slotDate = this.$moment(this.slotObject.date);
      const endSlotTime = slotDate.tz(this.$store.getters["auth/currentClub"].timezone).add(this.slotObject.step, 'minutes').format('HH:mm');

      if (planningDate > today) {
        return 'next-date-slot-background';
      } else if (planningDate < today) {
        return 'previous-date-slot-background';
        //}  else if (planningDate === today && endSlotTime < timeNow) {
        //return 'passed-date-slot-background'
      } else {
        return this.slotAvailability;
      }
    },
    slotLabel() {
      let startAt = this.$moment(this.slotObject.date);
      let endAt = startAt.clone().add(this.slotObject.step, 'minutes');

      return this.dateToTimezoneTime(startAt) + ' - ' + this.dateToTimezoneTime(endAt);
    },
    getComponentSlot() {
      if (this.orientation === 'vertical') {
        return (this.slotHeight > 38) ? Medium.name : Small.name;
      } else {
        return Medium.name;
      }
    }
  },
  methods: {
    newMultipleBooking() {
      enableBodyScrollY();
      this.$router.push({
        name: 'booking_new_index',
        params: {
          slotData: this.slotProps.slotObject,
          playgoundSelected: this.slotProps.playground
        }
      });
    },
    loadNbParticipantBySlot () {
      const slotStartAt = this.slotObject.date;
      const slotEndAt = this.slotObject.date.clone();
      slotEndAt.add(this.slotObject.step, 'minutes');

      for (const item of this.leisureSlots.items) {
        if (item.startAt === slotStartAt.format('HH:mm') && item.endAt === slotEndAt.format('HH:mm')) {
          this.participantsCount = item.participantsCount;
          this.bookingsCount = item.bookingsCount;
          break;
        }
      }
    },
    displayResume() {
      if (this.nbParticipants === 0 && this.nbBookings === 0) {
        this.newMultipleBooking();
      } else {
        this.resumeKey++;
        this.resumeModel = this.slotObject;

        this.$nextTick(() => {
          disableBodyScrollY();
          this.display = true;
        });
      }
    },
    onHide() {
      enableBodyScrollY();
      this.display = false;
      this.resumeKey++;
      this.resumeModel = null;
    },
    dateToTimezoneTime(date) {
      return toTimeFormat(date);
    },
    setStyleSlot(height, date) {
      const timezone = this.$store.getters["auth/currentClub"].timezone;
      const hhFormat = this.$moment.utc(date).tz(timezone).format('HH');
      const mmFormat = this.$moment.utc(date).tz(timezone).format('mm');
      const slotDate = this.$moment.utc(storedDate).tz(timezone);
      const storedDate = this.$store.getters["planning/getDate"];

      slotDate.set({hour: hhFormat, minute: mmFormat, second: 0, millisecond: 0});
      slotDate.toISOString();
      slotDate.format();

      if (this.orientation === 'vertical') {
        return {
          'height': (height * this.size) + 'px',
          'order': '' + slotDate.unix(),
        }
      } else {
        return {
          'min-width': (2 * height * this.size) + 'px',
          'max-width': (2 * height * this.size) + 'px',
          'border-right': '1pt solid rgba(100, 100, 100, 0.1)',
          'display': 'flex',
          'order': '' + slotDate.unix(),
        }
      }
    },
  },
  created () {
    this.loadNbParticipantBySlot();
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/planning/multiple-slot/_background-color";

.hover-slot {
  border-bottom: 1pt solid rgba(100, 100, 100, 0.1);
  background-color: white;
}

.hover-slot:hover {
  background-color: #508FF458;
  opacity: 0.8;
  border-radius: 6px;
}

.empty-slot-hover:hover {
  background-color: #b9fab6;
  opacity: 0.8;
}

.medium-slot-hover:hover {
  background-color: #e7d495;
  opacity: 0.8;
}

.full-slot-hover:hover {
  background-color: #f8b9b9;
  opacity: 0.8;
}
</style>
