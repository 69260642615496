<template>
  <div>
    <span
      :class="labelColor"
      class="start-end-slot d-block justify-content-center d-flex"
    >
      {{ label }}
    </span>
    <span
      v-if="nbParticipants > 0"
      :class="slotLabelSize"
      class="d-block justify-content-center d-flex"
    >
      {{ nbParticipants + slashSpace + maxParticipantsCountLimit }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'Medium',
  props: [
    'slotLabel',
    'slotWidth',
    'labelColor',
    'orientation',
    'nbParticipants',
    'maxParticipantsCountLimit'
  ],
  computed: {
    slotLabelSize() {
      if (this.orientation === 'vertical') {
        return 'participants-vertical-label';
      } else {
        return this.slotWidth < 40 ? 'participants-horizontal-label' : 'participants-vertical-label';
      }
    },
    slashSpace() {
      if (this.orientation === 'horizontal') {
        return this.slotWidth < 40 ? '/' : ' / ';
      } else {
        return ' / ';
      }
    },
    label() {
      return this.orientation === 'vertical' ? this.slotLabel : this.slotLabel.split('-')[0];
    }
  }
}
</script>
<style scoped lang="scss">
@import "@lazy/planning/multiple-slot/_label.scss";
</style>
