<template>
  <unique-slot
    v-if="!isBusy && isUniqueBookingType"
    :size="size"
    :step-slot="stepSlot"
    :playxground="playground"
    :slot-object="slotObject"
    :orientation="orientation"
    @on:slot-click="addNewBooking"
  />
  <multiple-slot
    v-else-if="!isBusy && !isUniqueBookingType"
    :size="size"
    :step-slot="stepSlot"
    :playground="playground"
    :slot-object="slotObject"
    :orientation="orientation"
    :leisure-slots="leisureSlots"
  />
  <div
    :style="setStyleSlot(slotObject.step, slotObject.date)"
    class="disabled-slot"
    v-else
  >
    <div
      :style="(orientation === 'vertical')?'height:' + (slotObject.step * size) + 'px':'width:' + (slotObject.step * size * 2) + 'px'"
    >
      <img
        :src="srcLoader"
        :style="'margin-top:' + (slotObject.step * size) / 2.5 + 'px;margin-left: ' + (slotObject.step * size) / 3.5 + 'px'"
        height="100%"
        width="100%"
      />
    </div>
  </div>
</template>
<script>
import {addBooking, updateBooking, getBooking} from "@api/doinsport/services/bookings/booking.api";
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import {LESSON_BOOKING_TYPE} from "@/classes/doinsport/BookingModel";
import UniqueSlot from "@views/planning/schedule/playgrounds/slots/UniqueSlot";
import MultipleSlot from "@views/planning/schedule/playgrounds/slots/MultipleSlot";

export default {
  components: {MultipleSlot, UniqueSlot},
  data: () => ({
    isBusy: false,
    display: false,
    srcLoader: require('@/assets/planning-loader.svg'),
    bookingModel: null,
    allowedAction: false,
  }),
  props: {
    orientation: {
      type: String,
      default: () => 'vertical'
    },
    slotObject: {
      type: Object,
      default: () => {
      },
    },
    leisureSlots: {
      type: Object,
      default: () => {
      },
    },
    playground: {
      type: Object,
      default: () => {
      },
    },
    stepSlot: {
      type: Number,
      default: 0,
    },
    size: {
      type: Number,
      default: 1,
    }
  },
  computed: {
    isUniqueBookingType () {
      return this.playground.bookingType === 'unique';
    }
  },
  methods: {
    checkPlaygroundActivity(type, id) {
      return getBooking(id)
        .then(resp => {
          this.bookingModel = resp.data;
        })
        .finally(() => {
          const playgroundActivity = this.playground.activities.find(el => el === this.bookingModel.activity['@id']);

          if (isNotUndefinedAndNotNull(playgroundActivity)) {
            this.allowedAction = true;

          } else {
            const message = this.$t('error-booking-operation') + this.$t(type + '_booking').toLowerCase() + this.$t('operation-not-supported') + this.bookingModel.activity.name + '».';
            this.$flash(null, message);
            this.isBusy = false;
            this.allowedAction = false;
          }
        })
        ;
    },
    proceedOperation(operation) {
      switch (operation.type) {
        case 'move':
          this.moveBooking(this.createMoveOperation());
          break;
        case 'copy':
          this.copyBooking(this.createCopyOperation());
          break;
        default:
          console.error('Unsupportable operation type', operation.type);
      }
    },
    addNewBooking() {
      const operation = JSON.parse(JSON.stringify(this.$store.getters["copyMove/getOperation"]));
      this.$store.commit('copyMove/set', {bookingId: null, type: null});

      if (isNotUndefinedAndNotNull(operation.type)) {
        this.isBusy = true;

        getBooking(operation.bookingId)
          .then(resp => {
            this.bookingModel = resp.data;
          })
          .finally(() => {
            const playgroundActivity = this.playground.activities.find(el => el === this.bookingModel.activity['@id']);

            if ("undefined" !== typeof playgroundActivity) {
              this.proceedOperation(operation);
            } else {
              const message = this.$t('error-booking-operation') + this.$t(operation.type + '_booking').toLowerCase() + this.$t('operation-not-supported') + this.bookingModel.activity.name + '».';
              this.$flash(null, message);

              this.isBusy = false;
            }
          })
        ;
      } else {
        this.$router.push({
          name: 'booking_new_index',
          params: {
            slotData: this.slotObject,
            playgoundSelected: this.playground
          }
        });
      }

    },
    createMoveOperation() {
      const startAt = this.slotObject.date;
      const duration = this.$moment.utc(this.bookingModel.endAt).diff(this.$moment.utc(this.bookingModel.startAt), 'minutes');
      const endAt = startAt.clone();
      endAt.add(duration, 'minutes');

      return {
        startAt: startAt.utc().format(),
        endAt: endAt.utc().format(),
        playgrounds: [this.playground['@id']],
        userClub: this.$store.getters["auth/currentUser"]['@id'],
      }
    },
    createCopyOperation() {
      let participants = [];
      let formattedOptions = [];

      const startAt = this.slotObject.date;
      const duration = this.$moment.utc(this.bookingModel.endAt).diff(this.$moment.utc(this.bookingModel.startAt), 'minutes');
      const endAt = startAt.clone();

      endAt.add(duration, 'minutes');

      for (const participant of this.bookingModel.participants) {
        participants.push({
          user: participant.user ? participant.user['@id'] : null,
          client: participant.client ? participant.client['@id'] : null,
          addedBy: participant.added,
          canceled: participant.canceled,
          category: participant.categorical,
          customData: participant.customData,
          subscriptionCard: participant.subscriptionCard ? participant.subscriptionCard['@id'] : null,
        });
      }

      if (this.bookingModel.playgroundOptions.length > 0) {
        for (const dataOption of this.bookingModel.playgroundOptions) {
          formattedOptions.push({
            quantity: dataOption.quantity,
            option: dataOption.option['@id']
          })
        }
      }
      const clonedBooking = {
        category: this.bookingModel.category ? this.bookingModel.category['@id'] : null,
        comment: this.bookingModel.comment,
        name: this.bookingModel.name,
        creationOrigin: 'administration',
        maxParticipantsCountLimit: this.bookingModel.maxParticipantsCountLimit,
        playgroundOptions: formattedOptions,
        userClient: this.bookingModel.userClient ? this.bookingModel.userClient['@id'] : null,
        userClub: this.$store.getters["auth/currentUser"]['@id'],
        client: this.bookingModel.client ? this.bookingModel.client['@id'] : null,
        timetableBlockPrice: this.bookingModel.timetableBlockPrice ? this.bookingModel.timetableBlockPrice['@id'] : null,
        endAt: endAt.utc().format(),
        startAt: startAt.utc().format(),
        activity: this.bookingModel.activity ? this.bookingModel.activity['@id'] : null,
        paymentMethod: 'on_the_spot',
        club: this.$store.getters["auth/currentClub"]['@id'],
        participants: participants,
        playgrounds: [this.playground['@id']],
      };

      if (this.bookingModel.activityType === LESSON_BOOKING_TYPE) {
        clonedBooking.registrationAvailableOnline = this.bookingModel.registrationAvailableOnline;
        clonedBooking.maxParticipantsCountLimit = this.bookingModel.maxParticipantsCountLimit;
        clonedBooking.registrationTimeBeforeStart = this.bookingModel.registrationTimeBeforeStart;
      }

      return clonedBooking;
    },
    moveBooking(data) {
      updateBooking(this.bookingModel.id, data)
        .then(result => {
          if (result.status === 200) {
            this.$emit('on:booking-move', {booking: result.data, dataChange: data});
            const playgroundIds = {
              from: this.bookingModel.playgrounds[0].id,
              to: this.playground.id,
              booking: result.data
            };
            this.$emit('on:load', playgroundIds);

            this.$bus.$emit('on:booking-moved', playgroundIds);
          }
        })
        .finally(() => {
          this.isBusy = false;
        })
      ;
    },
    copyBooking(booking) {
      addBooking(booking)
        .then(result => {
          if (result.status === 201) {
            this.$emit('on:booking-copy', {booking: result.data});
            this.$emit('on:load', {
              data: {
                from: this.bookingModel.playgrounds[0].id,
                to: this.playground.id
              }
            });
          }
        })
        .finally(() => {
          this.isBusy = false;
        })
      ;
    },
    setStyleSlot(height, date) {
      const timezone = this.$store.getters["auth/currentClub"].timezone;
      const hhFormat = this.$moment.utc(date).tz(timezone).format('HH');
      const mmFormat = this.$moment.utc(date).tz(timezone).format('mm');
      const storedDate = this.$store.getters["planning/getDate"];
      const slotDate = this.$moment.utc(storedDate).tz(timezone);

      slotDate.set({hour: hhFormat, minute: mmFormat, second: 0, millisecond: 0});
      slotDate.toISOString();
      slotDate.format();

      if (this.orientation === 'vertical') {
        return {
          'height': (height * this.size) + 'px',
          'order': '' + slotDate.unix(),
        }
      } else {
        return {
          'min-width': (2 * height * this.size) + 'px',
          'max-width': (2 * height * this.size) + 'px',
          'border-right': '1pt solid rgba(100, 100, 100, 0.1)',
          'order': '' + slotDate.unix(),
        }
      }
    }
  }
  ,
}
</script>
<style scoped lang="scss">
.disabled-slot {
  background: repeating-linear-gradient(45deg, #eff4f5, #e8e9ec 7px, #f1f0f0 7px, #efefef 14px);
  border-bottom: 1pt solid rgba(100, 100, 100, 0.1);
}
</style>
